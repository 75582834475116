import DApp from '@components/DApp/DApp';
import DDivider from '@components/DDivider/DDivider';
import DFooter from '@components/DFooter/DFooter';
import DHeader from '@components/DHeader/DHeader';
import DImageGrid from '@components/DImageGrid/DImageGrid';
import DMain from '@components/DMain/DMain';
import DMdContent from '@components/DMdContent/DMdContent';
import DSeo from '@components/DSeo/DSeo';
import '@fragments/SMdContent';
import { graphql } from 'gatsby';
import React from 'react';

export default function CategoryPage({ data, pageContext, location }) {
    const { dStrapi } = data;
    const { imageCategoryBySlug, footer } = dStrapi;
    const { indexPage } = pageContext;
    const canGoBack = location.state ? location.state.canGoBack : false;

    const page = imageCategoryBySlug;
    const content = [];
    page.content.forEach((current, index) => {
        if(current.__typename === 'DSTRAPI_ComponentGeneralMdContent')
            content.push(<DMdContent key={index} content={current.content} className="px-1"></DMdContent>);
        if(current.__typename === 'DSTRAPI_ComponentGeneralDivider')
            content.push(<DDivider key={index} data={current}></DDivider>);
        if(current.__typename === 'DSTRAPI_ComponentImageImageGrid')
            content.push(<DImageGrid key={index} data={current}></DImageGrid>);
    });

    return (
        <DApp>
            <DSeo title={page.metaTitle} otherTags={page.meta} />
            <DHeader backLink={{ title: indexPage.title, path: '/' }} canGoBack={canGoBack} />
            <DMain>
                {page.title && <h1 className="big-title color-primary--darken silver-belly text-center">{page.title}</h1>}
                {content}
            </DMain>
            <DFooter data={footer} />
        </DApp>
    )
}

export const query = graphql`
query getCategoryPageData($slug: String!) {
    dStrapi {
        ... SFooter
        imageCategoryBySlug(slug: $slug) {
            slug
            title
            metaTitle
            meta {
                name
                content
            }
            content {
                __typename
                ... SMdContent
                ... SDivider
                ... SImageGrid
            }
        }
    }
}`